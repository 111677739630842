@tailwind utilities;

@layer utilities {
  .custom-event {
    @apply bg-bg-primary text-content-white rounded-m;

    .rbc-event {
      @apply bg-bg-primary text-content-white;
      border: 1px solid #fdfdfd !important;
    }

    .rbc-selected {
      @apply bg-bg-primary;
    }
  }
  /* .custom-event div {
    @apply bg-bg-primary text-content-white;
  } */

  .custom-show-more {
    .rbc-show-more {
      @apply text-content-secondary;
    }
    .rbc-show-more:hover {
      @apply text-content-secondary;
    }

    .rbc-show-more:focus {
      @apply text-content-secondary;
    }
  }

  .custom-today {
    .rbc-today {
      @apply bg-bg-secondary;
    }
  }
}
